// Use This URL for testing
const URL = "consiptechnology.com";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "IDBI Bank",
  "Bank A/c. No.": "0601102000011839",
  "IFSC Code": "IBKL0000601",
};

export const weightUncertaintySupportiveValues ={
  barometer: 2.4,
  tempIndicator:0.58,
  rhIndicator:1.74,
  weightBox:7950,
};

export const EccentricityDiagramInstruemntID = 0;
export const G_Value = 9.80665;

export const GST_NUMBER = "27AAQCA4640K1ZB";

export const DISABLED_MODULE =["Expenses","Customer Complaints","Customer Feedback","Document Upload","Purchase"
,"Client Complaints","Client Feedback","CoordinatesAddress","Settings","Datasheet Template","Discipline","WCR Report","OfflineMode","Department Analytics","Supplier Invoice","Training Resources","Inward Register","WCR Report","Uncertainty"]; 

export const DEVICE_TYPE = ["desktop","tablet", "mobile"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6";

export const BackDateShow = true;